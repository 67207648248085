.formWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  margin-bottom: 16px;

  & label {
    background-color: #F7F7F7;
  }
}

.formControl {
  width: 100%;

  & input {
    font-size: 12px;
  }
}

.searchIconButton {
  padding: 8px;
  margin-left: auto;

  & svg {
    font-size: 20px;
    color: #777;
  }
}

.loadingProgressWrapper {
  display: inline-flex;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.actionWrapper {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.searchButton {
  & svg {
    margin-right: 6px;
  }
}

.removeButton {
  position: absolute;
  top: 0;
  left: 0;
  height: 32px;

  & svg {
    margin-right: 6px;
    font-size: 18px;
  }
}
