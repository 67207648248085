.wrapper {
  padding-bottom: 12px;
  margin-bottom: 24px;
  margin-top: 4px;
  border-bottom: 1px solid #EBEAEF;
}

.wrapperInline {
  display: flex;

  & .formControl {
    flex: 1;
    margin-right: 16px;
    margin-bottom: 0;

    &:last-child {
      margin-right: 0;
    }
  }
}

.formControl {
  padding-top: 16px;
  margin-bottom: 16px;
}

.radioGroup {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-left: 4px;

  & > label {
    width: 50%;
    margin-right: 0;
    margin-bottom: 4px;
  }
}

.radioLabel {
  font-size: 13px;
}

.radioInput {
  padding: 4px;
}

.checkboxRoot {
  margin-top: -12px;
  margin-bottom: 6px;
}

.checkboxControl {
  margin-top: -12px;
}

.checkboxLabel {
  font-size: 12px;
}

.tooltipWrapper {
  display: flex;
  align-items: center;

  & svg {
    margin-left: 4px;
    color: #777;
    font-size: 16px;
  }
}

.yearRangeFormControl {
  margin-top: 16px;
  margin-bottom: 16px;
}

.groupList {
  background-color: #fff;
}

.actionWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}
