.wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 3000;
  transition: all .5s ease;
  transform: translate3d(410px, -456px, 0);

  @media screen and (max-width: 960px) {
    transform: translate3d(410px, -456px, 0);
  }
}

.wrapperWithoutHeader {
  transform: translate3d(16px, -456px, 0);

  @media screen and (max-width: 768px) {
    transform: translate3d(16px, -456px, 0);
  }
}

.wrapperWithBeforeAndAfterMosaic {
  @media screen and (max-width: 768px) {
    bottom: 24px;
  }
}

.controlWrapper {
  display: flex;
  align-items: center;
  height: 32px;
  background-color: rgba(255, 255, 255, .5);
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  border-radius: 6px;
}

.searchButton {
  width: 32px;
  height: 32px;
  padding: 0;
  background-color: white;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 200ms ease;
  border-radius: 6px;

  &:hover {
    background-color: #F1F1F1;
  }

  & svg {
    font-size: 22px;
    fill: #555;
  }

  @media screen and (max-width: 1024px) {
    border-radius: 6px;
  }
}

.title {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;
}

.formWrapper {
  position: absolute;
  left: 44px;
  bottom: 0;
  width: 432px;
  display: flex;
  align-items: center;
  padding: 26px 10px 12px 12px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  border-radius: 6px;

  & h3 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    color: #16a085;
  }

  & label {
    background-color: #F7F7F7;
  }

  @media screen and (max-width: 768px) {
    width: 280px;
    padding: 16px 10px 12px 10px;
  }
}

.textInfo {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 1.4;
}

.formControl {
  width: 360px;
  margin-right: 12px;

  & input {
    font-size: 12px;
  }
}

.searchIconButton {
  padding: 8px;
  margin-left: auto;

  & svg {
    font-size: 20px;
    color: #777;
  }
}

.infoIcon {
  margin-right: 8px;
  font-size: 18px;
  fill: #555;
  cursor: help;
}

.loadingProgressWrapper {
  display: inline-flex;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
}

.carInfoWrapper {
  position: absolute;
  left: 0;
  top: 16px;
  z-index: 3000;
  width: 432px;
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  border-radius: 6px;
  transition: all .5s ease;
  transform: translate3d(452px, 0, 0);
}

.carInfoWrapperWithoutHeader {
  transform: translate3d(348px, 0, 0);
}

.header {
  position: relative;
}

.title {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.closeButton,
.toggleButton {
  position: absolute;
  top: -6px;
  right: -6px;
  padding: 6px;

  & svg {
    font-size: 18px;
    transition: transform .5s ease;
  }
}

.toggleButton {
  width: 30px;
  height: 30px;
  right: 32px;

  & svg {
    font-size: 22px;
  }
}

.toggleButtonCollapsed {
  & svg {
    transform: rotate(180deg);
  }
}

.dataList {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 16px;
  margin-bottom: 0;
}

.dataListItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  width: 50%;

  &:last-child {
    margin-bottom: 0;
  }
}

.dataListItemLabel {
  margin-bottom: 4px;
  font-size: 12px;
  color: #777;
}

.dataListItemValue {
  font-size: 12px;
  color: #444;
}
