.baseClassInfo {
  display: block;
  padding: 6px 12px;
  margin-top: 18px;
  margin-bottom: 32px;
  font-size: 12px;
  color: #155724;
  line-height: 1.4;
  background-color: #d4edda;
  border-radius: 5px;
}

.formControl {
  margin-bottom: 16px;
}
