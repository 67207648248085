.baseClassInfo,
.baseClassInfoSecondary {
  display: block;
  padding: 6px 12px;
  margin-top: 18px;
  margin-bottom: 32px;
  font-size: 12px;
  color: #155724;
  line-height: 1.4;
  background-color: #d4edda;
  border-radius: 5px;
}

.baseClassInfoSecondary {
  color: #383d41;
  background-color: #e2e3e5;
}

.formControl {
  margin-bottom: 32px;
}

.actionWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: -12px;
}
