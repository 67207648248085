.wrapper {
  padding: 0 16px;
  margin-bottom: 24px;
  margin-top: 4px;
  border-bottom: 1px solid #EBEAEF;
}

.wrapperInline {
  display: flex;

  & .formControl {
    flex: 1;
    margin-right: 16px;
    margin-bottom: 0;

    &:last-child {
      margin-right: 0;
    }
  }
}

.radioGroup {
  flex-direction: row;
  margin-bottom: 16px;
  margin-left: 4px;

  & > label {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.radioLabel {
  font-size: 13px;
}

.radioInput {
  padding: 4px;
}
