.wrapper {
  margin-top: 16px;
  padding-top: 24px;
  border-top: 1px solid #ebeaef;
}

.title {
  margin-top: 0;
  margin-bottom: 16px;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: .07em;
  color: #353f4d;
}

.classList {
  padding: 0;
  margin: 0;
}

.classListItemBase {
  border-bottom: 1px solid #DEDEDE;
}

.classListItemHeader {
  display: flex;
  align-items: center;
  padding: 6px 4px;
}

.classListItemColor {
  display: inline-flex;
  width: 16px;
  height: 16px;
  margin-right: 6px;
  border-radius: 4px;
}

.classListItemTitle {
  flex: 1;
  margin-left: 4px;
  margin-right: 4px;
  font-size: 13px;
  color: #555;
}
