.bar {
  position: relative;
  padding-bottom: 12px;
  margin-bottom: 8px;
}

.backgroundBar,
.mainBar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 16px;
  border-radius: 10px;
  background-color: #E2EFE4;
}

.mainBar {
  width: 0%;
}

.infoContent {
  display: flex;
  align-items: center;
}

.infoContentValue {
  margin-right: 12px;
  font-size: 32px;
  font-weight: 900;
}

.infoContentText {
  font-size: 14px;
  font-weight: 400;
  max-width: 140px;
}
