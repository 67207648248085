.container {
  max-width: 900px;
  margin: 0 auto;
  font-size: 13px;
}

.searchContainer {
  display: flex;
  gap: 30px;
  padding: 30px;
  margin: 30px 0;
  background-color: #EEEEEE;
  border-radius: 2px;

  .formControl {
    flex: 2;
  }

  .buttonContainer {
    display: flex;
    flex: 1;
    padding-top: 21px;
  }
}

.label {
  display: block;
  margin-bottom: 5px;
  line-height: 16px;
  color: #7F8184;
}

.select {
  width: 100%;
  padding: 10px;
  border: 1px solid #CCCCCC;
  border-radius: 2px;
}

.button {
  flex: 1;
  padding: 10px;
  border: none;
  background-color: #84CAA0;
  text-transform: uppercase;
  color: white;
  border-radius: 2px;
}

.cards {
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
  text-align: center;

  span {
    display: block;
  }
}

.loadingContainer {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 32px);
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  z-index: 1000;
}

.card {
  flex: 1;
  padding: 30px 15px;
  border: 3px solid #EEEEEE;
  font-size: 12px;
}

.cardData {
  font-weight: 700;
  font-size: 2.5rem;
}

.lineCharts {
  display: flex;
  gap: 30px;

  .chart {
    flex: 1;

    p {
      display: flex;
      padding-left: 20px;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 15px;
    }
  }
}

.barChart {
  margin-top: 30px;
  & > p {
    text-transform: uppercase;
    text-align: center;
    font-size: 16px;
  }
}
