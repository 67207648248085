.mapWrapper {
  & :global(.leaflet-control-zoom),
  & :global(#box-zoom-button),
  & :global(.leaflet-left),
  & :global(.leaflet-right) {
    transition: all .5s ease;
  }

  & :global(.leaflet-left) {
    bottom: 18px;
    transform: translate3d(16px, -239px, 0);

    @media screen and (max-width: 768px) {
      transform: translate3d(10px, -239px, 0);
    }
  }

  & :global(.leaflet-right) {
    transform: translateX(0);
  }

  & :global(.leaflet-control-zoom) {
    margin: 0;
    top: -42px;
    left: 0;
    border-radius: 6px;
    overflow: hidden;

    @media screen and (max-width: 1024px) {
      //display: none;
    }
  }

  & :global(.leaflet-control-scale) {
    margin: 0;
    left: 136px;
    bottom: -255px;

    @media screen and (max-width: 1024px) {
      left: 44px;
      bottom: -190px;
    }
  }

  @media screen and (max-width: 1023px) {
    :global(#box-zoom-button) {
      // display: none;
    }

    :global(.leaflet-touch .leaflet-bar) {
      border: none !important;
    }
  }

  @media screen and (max-width: 768px) {
    :global(.leaflet-bottom .leaflet-control-scale) {
      display: none;
    }

    :global(#box-zoom-button) {
      bottom: -64px !important;
    }
  }
}

.mapWrapperWithHeader {
  & :global(.leaflet-left) {
    transform: translate3d(410px, -239px, 0);

    @media screen and (max-width: 960px) {
      transform: translate3d(390px, -239px, 0);
    }
  }

  & .mapSearchButton {
    transform: translate3d(410px, -215px, 0);

    @media screen and (max-width: 960px) {
      transform: translate3d(390px, -215px, 0);
    }
  }
}

.mapWrapperOnPointSearchMode {
  cursor: pointer;
}

.mapActionButton {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2000;
  width: 32px;
  height: 32px;
  padding: 0;
  background-color: white;
  border: none;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  outline: none;
  cursor: pointer;
  transition: all .5s ease;
  border-radius: 6px;

  &:hover {
    background-color: #F1F1F1;
  }

  & svg {
    font-size: 22px;
    fill: #555;
  }
}

.mapSearchButton {
  transform: translate3d(16px, -215px, 0);

  @media screen and (max-width: 768px) {
    transform: translate3d(10px, -215px, 0);
    z-index: 2000;
  }
}

.tutorialButton {
  bottom: 367px;

  @media screen and (max-width: 768px) {
    right: 10px;
    z-index: 2000;
  }
}

.mapGeographicalReliefButton {
  bottom: 24px;
}

.loadingWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, .9);
  transform: translateX(-50%) translateY(-50%);
}

.opacityBox {
  position: absolute;
  bottom: 100px;
  right: 100px;
  z-index: 2000;
  width: 50px;
  height: 120px;
  padding: 8px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  transition: all .5s ease;
}
