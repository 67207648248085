.box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 300px;
  border-radius: 10px;
  overflow: hidden;

  & img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.boxBlack {
  background-color: #333;
}
