.bigNumberContainer {
  display: flex;
  justify-content: space-evenly;
  margin-top: 24px;
}

.bigNumberItem {
  display: flex;
  flex-direction: column;
}

.bigNumber {
  margin-top: 8px;
  margin-bottom: 6px;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
}

.bigNumberInfo {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  text-align: center;
}
