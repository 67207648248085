.control {
  position: relative;
  margin-bottom: 18px;
}

.groupList {
  background-color: #fff;
}

.removeButton {
  position: absolute;
  top: 10px;
  right: 32px;
  padding: 3px;

  & svg {
    font-size: 18px;
  }
}
