.secondaryTitle {
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  color: #555;

  & > small {
    display: block;
    margin-top: 4px;
    font-size: 11px;
    font-weight: 400;
    color: #616161;
  }

  & svg {
    position: relative;
    top: 3px;
  }
}

.sectionTitleBoxInfo {
  margin-top: 0;
  margin-bottom: 16px;
  margin-right: 40px;
  font-size: 16px;
  line-height: 1.4;
  color: #444;
}
