.wrapper {
  margin-bottom: 24px;
}

.formControl {
  margin-top: 32px;
}

.actionButtonActive {
  color: white !important;
  background-color: #199272;

  &:hover {
    color: white;
    background-color: #199272;
  }
}

.infoText,
.infoTextSecondary,
.infoTextError {
  display: block;
  padding: 6px 12px;
  margin-top: 18px;
  margin-bottom: 18px;
  font-size: 12px;
  color: #155724;
  line-height: 1.4;
  background-color: #d4edda;
  border-radius: 5px;
}

.infoTextSecondary {
  color: #0c5460;
  background-color: #d1ecf1;
}

.infoTextError {
  color: #721c24;
  background-color: #f8d7da;
}

.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  & > button {
    width: 48%;
    color: #5c5c5c;
  }
}
