.wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 4000;
  display: flex;
  flex-direction: column;
  transition: all .5s ease;
  transform: translate3d(410px, -109px, 0);

  @media screen and (max-width: 960px) {
    transform: translate3d(390px, -109px, 0);
  }
}

.baseMapsItemWrapper {
  display: flex;
  justify-content: space-between;
}

.baseMapsContentWrapper {
  max-height: 271px;
  overflow: scroll;
}

.wrapperWithoutHeader {
  transform: translate3d(16px, -109px, 0);

  @media screen and (max-width: 768px) {
    transform: translate3d(10px, -109px, 0);
  }
}

.wrapperWithBeforeAndAfterMosaic {
  @media screen and (max-width: 768px) {
    bottom: 24px;
  }
}

.actionBox {
  position: relative;

  &:first-child button {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:last-child button {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

.actionBoxContent {
  position: absolute;
  left: 42px;
  bottom: 0;
  width: 400px;
  padding: 10px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);

  & h3 {
    margin-top: 0;
    margin-bottom: 12px;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .07em;
    color: #353f4d;
  }

  @media screen and (max-width: 420px) {
    width: 260px;
  }

  @media screen and (max-width: 320px) {
    width: 240px;
  }
}

.actionBoxContentOpacity {
  display: flex;
  justify-content: center;
  width: 40px;
  height: 160px;
  padding: 16px 0 10px;
}

.actionButton {
  width: 32px;
  height: 32px;
  padding: 2px 0 0 0;
  background-color: white;
  border: none;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: #F7F7F7;
    border-color: #F7F7F7;
  }

  & svg {
    font-size: 22px;
    fill: #555;
  }
}

.actionButtonDisabled {
  pointer-events: none;

  & svg {
    fill: #CACACA;
  }
}

.switchControl,
.radioGroupControl {
  width: 100%;
  margin-left: 0;
}

.switchControl {
  margin-bottom: 4px;
}

.radioGroupControl {
  margin-bottom: 6px;

  &:last-child {
    margin-bottom: 0;
  }
}

.switchLabel,
.radioGroupLabel {
  margin-left: 6px;
  font-size: 12px;
  color: #555;
}

.radioGroupInput {
  padding: 0;
}

.actionBoxContentScrollWrapper {
  max-height: 380px;
  overflow-y: scroll;

  @media screen and (max-height: 720px) {
    max-height: 236px;
    overflow-y: scroll;
  }
}

.actionBoxItemWrapper {
  display: flex;
  flex-direction: column;
}

.actionBoxItemHeader {
  display: flex;
}

.actionBoxItemControl {
  flex: 1;
}

.actionBoxControlButton {
  width: 24px;
  height: 24px;
  padding: 0;
  margin-right: 4px;

  & svg {
    font-size: 16px;
  }

  &.actionBoxControlButtonToggle {
    & svg {
      font-size: 20px;
    }
  }
}

.actionBoxItemLegend {
  padding: 4px 8px;
  margin-left: 46px;
  margin-bottom: 12px;
  background-color: #F1F1F1;

  & p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 1.4;
    color: #555;
  }

  & ul {
    padding: 0;
    margin: 0;

    & li {
      margin-bottom: 4px;
      font-size: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      & > span {
        &:first-child {
          color: #777;
        }

        &:last-child {
          color: #444;
        }
      }
    }
  }
}
