.wrapper {
  padding: 16px;
  background-color: white;
  width: 420px;
  position: absolute;
  top: 2px;
  z-index: 4000;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  transition: width .5s ease, transform .5s ease, opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  left: 16px;
  overflow-y: auto;
  border-radius: 6px;
  height: calc(100% - 200px);
  left: 0px;
  transform: translate(60px, 84px);

  @media screen and (max-width: 620px) {
    width: calc(100% - 60px);
    height: calc(100% - 148px);
    left: 50px;
    transform: translate(0px, 80px);
  }
}

.wrapperWithHeader {
  height: calc(100% - 200px);
  left: 0px;
  transform: translate(450px, 12px);
}

.header {
  position: relative;
}

.title {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}


.titleCar {
  margin: 12px 0px 0px;
  font-size: 16px;
  font-weight: 600;
}

.closeButton {
  position: absolute;
  top: -6px;
  right: -6px;
  padding: 6px;

  & svg {
    font-size: 18px;
  }
}

.dataList {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 16px;
  margin-bottom: 0;
}

.dataListCar{
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 12px;
  margin-bottom: 0;
}

.dataListItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  width: 50%;

  &:last-child {
    margin-bottom: 0;
  }
}
.dataListItemFull {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;

  span{
    margin-left: 5px;
    margin-bottom: 4px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.dataListCarItemFull {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-top: 6px;
  margin-bottom: 6px;
  width: 100%;

  span{
    margin-left: 5px;
    margin-bottom: 4px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.dataListItemLabel {
  margin-bottom: 4px;
  font-size: 12px;
  color: #777;
}

.dataListItemValue {
  font-size: 12px;
  color: #444;
}

.chartWrapper {
  margin-top: 16px;
  margin-bottom: 16px;
}